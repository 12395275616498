import CountryLanguageSelector from '@cms/components/CountryLanguageSelector'
import type { NextJsDataProps } from '@cms/pages'
import { isDefaultOrEmptyLocale } from '@cms/utils/utils'
import Page from '@components/Layout/Page'
import i18nConfig from '@config/i18n'
import { useTheme } from '@mui/material/styles'

const LanguageSelector: React.FC<NextJsDataProps> = ({
  headerEntries,
  footerEntries,
  locale,
  appsConfig,
}) => {
  const theme = useTheme()

  return (
    <Page
      headerEntries={headerEntries}
      footerEntries={footerEntries}
      isMiniFooter={isDefaultOrEmptyLocale(locale)}
      isMiniHeader={isDefaultOrEmptyLocale(locale)}
      locale={locale}
      sx={{
        '& .page-wrapper': {
          backgroundColor: theme.palette.background.background,
        },
      }}
      appsConfig={appsConfig}
    >
      <CountryLanguageSelector
        dataCy="language-selector"
        regions={{
          europe: i18nConfig?.countries?.europe,
          america: i18nConfig?.countries?.america,
          asiaPacific: i18nConfig?.countries?.asia,
          africaMiddleEastIndia: i18nConfig?.countries?.africa,
          global: i18nConfig?.countries?.global,
        }}
      />
    </Page>
  )
}

export { getServerSideProps } from '../cms/pages'

export default LanguageSelector
