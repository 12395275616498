import type React from 'react'
import { useState } from 'react'
import { useTranslation } from 'next-i18next'

import { WEB_CONTAINER_VERTICAL_SPACE } from '@knauf-group/ct-designs/themeConfigs/constants'
import { ScreenXS } from '@knauf-group/ct-designs/utils/hooks/mediaQuery'
import type { Styles } from '@knauf-group/ct-designs/utils/types'
import SearchIcon from '@mui/icons-material/Search'
import Container from '@mui/material/Container'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import type { CountryLanguageSelectorProps } from './CountryLanguage.types'
import CountryLanguageAccordion from './CountryLanguageAccordion'
import CountryLanguageDesktop from './CountryLanguageDesktop'
import { filterCountries } from './utils'

export const CountryLanguageSelector: React.FC<CountryLanguageSelectorProps> = ({
  dataCy = 'language-selector',
  regions,
}) => {
  const { t } = useTranslation('cms', { keyPrefix: 'region' })
  const { t: tCommon } = useTranslation('cms', { keyPrefix: 'common' })
  const theme = useTheme()
  const [regionsFiltered, setRegionsFiltered] = useState(regions)
  const isScreenXs = ScreenXS(theme)
  const styles: Styles = {
    mainHeadline: {
      textAlign: 'center',
      '&.MuiTypography-root': {
        marginBottom: 0,
      },
    },
  }

  return (
    <Container data-cy={dataCy} sx={WEB_CONTAINER_VERTICAL_SPACE}>
      <Grid container display="flex" justifyContent="center">
        <Grid
          item
          xs={12}
          lg={8}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: theme.spacing(3), sm: theme.spacing(4) },
          }}
        >
          <Typography variant="h2" sx={styles.mainHeadline}>
            {t('chooseYourRegion')}
          </Typography>
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              onChange={(e) => setRegionsFiltered(filterCountries(e.target.value, regions))}
              placeholder={tCommon('searchLabel')}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
          {isScreenXs ? (
            <CountryLanguageAccordion data-cy={dataCy} regions={regionsFiltered} />
          ) : (
            <CountryLanguageDesktop data-cy={dataCy} regions={regionsFiltered} />
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default CountryLanguageSelector
